@font-face {
  font-family: 'saf';
  src: url('../font/saf.eot?28085483');
  src: url('../font/saf.eot?28085483#iefix') format('embedded-opentype'),
       url('../font/saf.woff2?28085483') format('woff2'),
       url('../font/saf.woff?28085483') format('woff'),
       url('../font/saf.ttf?28085483') format('truetype'),
       url('../font/saf.svg?28085483#saf') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'saf';
    src: url('../font/saf.svg?28085483#saf') format('svg');
  }
}
*/
[class^="saf-"]:before, [class*=" saf-"]:before {
  font-family: "saf";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.saf-align-left:before { content: '\e0d7'; } /* '' */
.saf-direct-down:before { content: '\e0e7'; } /* '' */
.saf-call-outgoing:before { content: '\e0f2'; } /* '' */
.saf-3d-square:before { content: '\e800'; } /* '' */
.saf-3d-cube-scan:before { content: '\e801'; } /* '' */
.saf-3d-rotate:before { content: '\e802'; } /* '' */
.saf-3square:before { content: '\e803'; } /* '' */
.saf-3dcube:before { content: '\e804'; } /* '' */
.saf-24-support:before { content: '\e805'; } /* '' */
.saf-aave-(aave):before { content: '\e806'; } /* '' */
.saf-activity:before { content: '\e807'; } /* '' */
.saf-align-horizontally:before { content: '\e808'; } /* '' */
.saf-add-square:before { content: '\e809'; } /* '' */
.saf-add:before { content: '\e80a'; } /* '' */
.saf-additem:before { content: '\e80b'; } /* '' */
.saf-airdrop:before { content: '\e80c'; } /* '' */
.saf-airplane-square:before { content: '\e80d'; } /* '' */
.saf-airplane:before { content: '\e80e'; } /* '' */
.saf-airpod:before { content: '\e80f'; } /* '' */
.saf-airpods:before { content: '\e810'; } /* '' */
.saf-alarm:before { content: '\e811'; } /* '' */
.saf-align-bottom:before { content: '\e812'; } /* '' */
.saf-facebook:before { content: '\e813'; } /* '' */
.saf-twitter:before { content: '\e814'; } /* '' */
.saf-align-right:before { content: '\e815'; } /* '' */
.saf-align-vertically:before { content: '\e816'; } /* '' */
.saf-android:before { content: '\e817'; } /* '' */
.saf-ankr-(ankr):before { content: '\e818'; } /* '' */
.saf-apple:before { content: '\e819'; } /* '' */
.saf-aquarius:before { content: '\e81a'; } /* '' */
.saf-archive-1:before { content: '\e81b'; } /* '' */
.saf-archive-2:before { content: '\e81c'; } /* '' */
.saf-archive-add:before { content: '\e81d'; } /* '' */
.saf-archive-book:before { content: '\e81e'; } /* '' */
.saf-archive-minus:before { content: '\e81f'; } /* '' */
.saf-archive-slash:before { content: '\e820'; } /* '' */
.saf-archive-tick:before { content: '\e821'; } /* '' */
.saf-archive:before { content: '\e822'; } /* '' */
.saf-arrange-circle-2:before { content: '\e823'; } /* '' */
.saf-arrange-circle:before { content: '\e824'; } /* '' */
.saf-arrange-square-2:before { content: '\e825'; } /* '' */
.saf-arrange-square:before { content: '\e826'; } /* '' */
.saf-arrow-2:before { content: '\e827'; } /* '' */
.saf-arrow-3:before { content: '\e828'; } /* '' */
.saf-arrow-bottom:before { content: '\e829'; } /* '' */
.saf-arrow-circle-down:before { content: '\e82a'; } /* '' */
.saf-arrow-circle-left:before { content: '\e82b'; } /* '' */
.saf-arrow-circle-right:before { content: '\e82c'; } /* '' */
.saf-arrow-circle-up:before { content: '\e82d'; } /* '' */
.saf-arrow-down-1:before { content: '\e82e'; } /* '' */
.saf-arrow-down-2:before { content: '\e82f'; } /* '' */
.saf-arrow-down:before { content: '\e830'; } /* '' */
.saf-arrow-left-1:before { content: '\e831'; } /* '' */
.saf-arrow-left-2:before { content: '\e832'; } /* '' */
.saf-arrow-left-3:before { content: '\e833'; } /* '' */
.saf-arrow-left:before { content: '\e834'; } /* '' */
.saf-arrow-right-1:before { content: '\e835'; } /* '' */
.saf-arrow-right-2:before { content: '\e836'; } /* '' */
.saf-arrow-right-3:before { content: '\e837'; } /* '' */
.saf-arrow-right-4:before { content: '\e838'; } /* '' */
.saf-arrow-right:before { content: '\e839'; } /* '' */
.saf-arrow-square-down:before { content: '\e83a'; } /* '' */
.saf-arrow-square-left:before { content: '\e83b'; } /* '' */
.saf-arrow-square-up:before { content: '\e83c'; } /* '' */
.saf-arrow-square:before { content: '\e83d'; } /* '' */
.saf-arrow-swap-horizontal:before { content: '\e83e'; } /* '' */
.saf-arrow-swap:before { content: '\e83f'; } /* '' */
.saf-arrow-up-1:before { content: '\e840'; } /* '' */
.saf-arrow-up-2:before { content: '\e841'; } /* '' */
.saf-arrow-up-3:before { content: '\e842'; } /* '' */
.saf-add-circle:before { content: '\e843'; } /* '' */
.saf-arrow:before { content: '\e844'; } /* '' */
.saf-attach-circle:before { content: '\e845'; } /* '' */
.saf-attach-square:before { content: '\e846'; } /* '' */
.saf-audio-square:before { content: '\e847'; } /* '' */
.saf-augur-(rep):before { content: '\e848'; } /* '' */
.saf-autobrightness:before { content: '\e849'; } /* '' */
.saf-autonio-(niox):before { content: '\e84a'; } /* '' */
.saf-avalanche-(avax):before { content: '\e84b'; } /* '' */
.saf-back-square:before { content: '\e84c'; } /* '' */
.saf-backward-5-seconds:before { content: '\e84d'; } /* '' */
.saf-award:before { content: '\e84e'; } /* '' */
.saf-backward-10-seconds:before { content: '\e84f'; } /* '' */
.saf-backward-15-seconds:before { content: '\e850'; } /* '' */
.saf-backward-item:before { content: '\e851'; } /* '' */
.saf-backward:before { content: '\e852'; } /* '' */
.saf-bag-2:before { content: '\e853'; } /* '' */
.saf-bag-cross:before { content: '\e854'; } /* '' */
.saf-bag-cross-1:before { content: '\e855'; } /* '' */
.saf-bag-happy:before { content: '\e856'; } /* '' */
.saf-bag-timer:before { content: '\e857'; } /* '' */
.saf-bag-tick-2:before { content: '\e858'; } /* '' */
.saf-bag:before { content: '\e859'; } /* '' */
.saf-bank:before { content: '\e85a'; } /* '' */
.saf-barcode:before { content: '\e85b'; } /* '' */
.saf-bag-tick:before { content: '\e85c'; } /* '' */
.saf-battery-charging:before { content: '\e85d'; } /* '' */
.saf-battery-disable:before { content: '\e85e'; } /* '' */
.saf-battery-empty-1:before { content: '\e85f'; } /* '' */
.saf-battery-empty:before { content: '\e860'; } /* '' */
.saf-battery-full:before { content: '\e861'; } /* '' */
.saf-be:before { content: '\e862'; } /* '' */
.saf-bezier:before { content: '\e863'; } /* '' */
.saf-bill:before { content: '\e864'; } /* '' */
.saf-binance-coin-(bnb):before { content: '\e865'; } /* '' */
.saf-binance-usd-(busd):before { content: '\e866'; } /* '' */
.saf-bitcoin-(btc):before { content: '\e867'; } /* '' */
.saf-bitcoin-card:before { content: '\e868'; } /* '' */
.saf-bitcoin-convert:before { content: '\e869'; } /* '' */
.saf-bitcoin-refresh:before { content: '\e86a'; } /* '' */
.saf-blend-2:before { content: '\e86b'; } /* '' */
.saf-blend:before { content: '\e86c'; } /* '' */
.saf-blogger:before { content: '\e86d'; } /* '' */
.saf-bluetooth-2:before { content: '\e86e'; } /* '' */
.saf-bluetooth-circle:before { content: '\e86f'; } /* '' */
.saf-bluetooth-rectangle:before { content: '\e870'; } /* '' */
.saf-bluetooth:before { content: '\e871'; } /* '' */
.saf-blur:before { content: '\e872'; } /* '' */
.saf-book-1:before { content: '\e873'; } /* '' */
.saf-book-saved:before { content: '\e874'; } /* '' */
.saf-book-square:before { content: '\e875'; } /* '' */
.saf-book:before { content: '\e876'; } /* '' */
.saf-bookmark-2:before { content: '\e877'; } /* '' */
.saf-bookmark:before { content: '\e878'; } /* '' */
.saf-bootstrap:before { content: '\e879'; } /* '' */
.saf-box-1:before { content: '\e87a'; } /* '' */
.saf-box-2:before { content: '\e87b'; } /* '' */
.saf-box-add:before { content: '\e87c'; } /* '' */
.saf-box-remove:before { content: '\e87d'; } /* '' */
.saf-arrow-up:before { content: '\e87e'; } /* '' */
.saf-box-tick:before { content: '\e87f'; } /* '' */
.saf-box-time:before { content: '\e880'; } /* '' */
.saf-box:before { content: '\e881'; } /* '' */
.saf-briefcase:before { content: '\e882'; } /* '' */
.saf-brifecase-cross:before { content: '\e883'; } /* '' */
.saf-brifecase-tick:before { content: '\e884'; } /* '' */
.saf-brifecase-timer:before { content: '\e885'; } /* '' */
.saf-broom:before { content: '\e886'; } /* '' */
.saf-brush-1:before { content: '\e887'; } /* '' */
.saf-brush-2:before { content: '\e888'; } /* '' */
.saf-brush-3:before { content: '\e889'; } /* '' */
.saf-brush-4:before { content: '\e88a'; } /* '' */
.saf-brush:before { content: '\e88b'; } /* '' */
.saf-bubble:before { content: '\e88c'; } /* '' */
.saf-bucket-circle:before { content: '\e88d'; } /* '' */
.saf-bucket-square:before { content: '\e88e'; } /* '' */
.saf-building-3:before { content: '\e88f'; } /* '' */
.saf-building-4:before { content: '\e890'; } /* '' */
.saf-building:before { content: '\e891'; } /* '' */
.saf-buildings-2:before { content: '\e892'; } /* '' */
.saf-buildings:before { content: '\e893'; } /* '' */
.saf-buliding:before { content: '\e894'; } /* '' */
.saf-bus:before { content: '\e895'; } /* '' */
.saf-buy-crypto:before { content: '\e896'; } /* '' */
.saf-cake:before { content: '\e897'; } /* '' */
.saf-calculator:before { content: '\e898'; } /* '' */
.saf-calendar-1:before { content: '\e899'; } /* '' */
.saf-calendar-2:before { content: '\e89a'; } /* '' */
.saf-calendar-add:before { content: '\e89b'; } /* '' */
.saf-calendar-circle:before { content: '\e89c'; } /* '' */
.saf-calendar-edit:before { content: '\e89d'; } /* '' */
.saf-calendar-remove:before { content: '\e89e'; } /* '' */
.saf-calendar-search:before { content: '\e89f'; } /* '' */
.saf-calendar-tick:before { content: '\e8a0'; } /* '' */
.saf-calendar:before { content: '\e8a1'; } /* '' */
.saf-call-add:before { content: '\e8a2'; } /* '' */
.saf-call-calling:before { content: '\e8a3'; } /* '' */
.saf-call-incoming:before { content: '\e8a4'; } /* '' */
.saf-call-minus:before { content: '\e8a5'; } /* '' */
.saf-box-search:before { content: '\e8a6'; } /* '' */
.saf-call-received:before { content: '\e8a7'; } /* '' */
.saf-call-remove:before { content: '\e8a8'; } /* '' */
.saf-call-slash:before { content: '\e8a9'; } /* '' */
.saf-call:before { content: '\e8aa'; } /* '' */
.saf-camera-slash:before { content: '\e8ab'; } /* '' */
.saf-camera:before { content: '\e8ac'; } /* '' */
.saf-candle-2:before { content: '\e8ad'; } /* '' */
.saf-candle:before { content: '\e8ae'; } /* '' */
.saf-car:before { content: '\e8af'; } /* '' */
.saf-card-add:before { content: '\e8b0'; } /* '' */
.saf-card-edit:before { content: '\e8b1'; } /* '' */
.saf-card-coin:before { content: '\e8b2'; } /* '' */
.saf-card-pos:before { content: '\e8b3'; } /* '' */
.saf-card-receive:before { content: '\e8b4'; } /* '' */
.saf-card-remove-1:before { content: '\e8b5'; } /* '' */
.saf-card-remove:before { content: '\e8b6'; } /* '' */
.saf-card-send:before { content: '\e8b7'; } /* '' */
.saf-card-slash:before { content: '\e8b8'; } /* '' */
.saf-card-tick-1:before { content: '\e8b9'; } /* '' */
.saf-card-tick:before { content: '\e8ba'; } /* '' */
.saf-card:before { content: '\e8bb'; } /* '' */
.saf-cardano-(ada):before { content: '\e8bc'; } /* '' */
.saf-cards:before { content: '\e8bd'; } /* '' */
.saf-category-2:before { content: '\e8be'; } /* '' */
.saf-category:before { content: '\e8bf'; } /* '' */
.saf-cd:before { content: '\e8c0'; } /* '' */
.saf-celo-(celo):before { content: '\e8c1'; } /* '' */
.saf-celsius-(cel)-:before { content: '\e8c2'; } /* '' */
.saf-chainlink-(link):before { content: '\e8c3'; } /* '' */
.saf-chart-1:before { content: '\e8c4'; } /* '' */
.saf-chart-2:before { content: '\e8c5'; } /* '' */
.saf-chart-3:before { content: '\e8c6'; } /* '' */
.saf-chart-21:before { content: '\e8c7'; } /* '' */
.saf-chart-fail:before { content: '\e8c8'; } /* '' */
.saf-chart-square:before { content: '\e8c9'; } /* '' */
.saf-chart-success:before { content: '\e8ca'; } /* '' */
.saf-chart:before { content: '\e8cb'; } /* '' */
.saf-check:before { content: '\e8cc'; } /* '' */
.saf-chrome:before { content: '\e8cd'; } /* '' */
.saf-civic-(cvc):before { content: '\e8ce'; } /* '' */
.saf-clipboard-close:before { content: '\e8cf'; } /* '' */
.saf-clipboard-export:before { content: '\e8d0'; } /* '' */
.saf-clipboard-import:before { content: '\e8d1'; } /* '' */
.saf-clipboard-text:before { content: '\e8d2'; } /* '' */
.saf-clipboard-tick:before { content: '\e8d3'; } /* '' */
.saf-clipboard:before { content: '\e8d4'; } /* '' */
.saf-clock-1:before { content: '\e8d5'; } /* '' */
.saf-clock:before { content: '\e8d6'; } /* '' */
.saf-close-circle:before { content: '\e8d7'; } /* '' */
.saf-close-square:before { content: '\e8d8'; } /* '' */
.saf-cloud-add:before { content: '\e8d9'; } /* '' */
.saf-cloud-change:before { content: '\e8da'; } /* '' */
.saf-cloud-connection:before { content: '\e8db'; } /* '' */
.saf-cloud-cross:before { content: '\e8dc'; } /* '' */
.saf-cloud-drizzle:before { content: '\e8dd'; } /* '' */
.saf-cloud-fog:before { content: '\e8de'; } /* '' */
.saf-cloud-lightning:before { content: '\e8df'; } /* '' */
.saf-cloud-minus:before { content: '\e8e0'; } /* '' */
.saf-cloud-notif:before { content: '\e8e1'; } /* '' */
.saf-cloud-plus:before { content: '\e8e2'; } /* '' */
.saf-cloud-remove:before { content: '\e8e3'; } /* '' */
.saf-cloud-snow:before { content: '\e8e4'; } /* '' */
.saf-cloud-sunny:before { content: '\e8e5'; } /* '' */
.saf-cloud:before { content: '\e8e6'; } /* '' */
.saf-code-1:before { content: '\e8e7'; } /* '' */
.saf-code-circle:before { content: '\e8e8'; } /* '' */
.saf-code:before { content: '\e8e9'; } /* '' */
.saf-coffee:before { content: '\e8ea'; } /* '' */
.saf-coin-1:before { content: '\e8eb'; } /* '' */
.saf-coin:before { content: '\e8ec'; } /* '' */
.saf-color-swatch:before { content: '\e8ed'; } /* '' */
.saf-colorfilter:before { content: '\e8ee'; } /* '' */
.saf-colors-square:before { content: '\e8ef'; } /* '' */
.saf-command-square:before { content: '\e8f0'; } /* '' */
.saf-command:before { content: '\e8f1'; } /* '' */
.saf-component:before { content: '\e8f2'; } /* '' */
.saf-computing:before { content: '\e8f3'; } /* '' */
.saf-convert-3d-cube:before { content: '\e8f4'; } /* '' */
.saf-convert-card:before { content: '\e8f5'; } /* '' */
.saf-convert:before { content: '\e8f6'; } /* '' */
.saf-convertshape-2:before { content: '\e8f7'; } /* '' */
.saf-convertshape:before { content: '\e8f8'; } /* '' */
.saf-copy-success:before { content: '\e8f9'; } /* '' */
.saf-copy:before { content: '\e8fa'; } /* '' */
.saf-copyright:before { content: '\e8fb'; } /* '' */
.saf-courthouse:before { content: '\e8fc'; } /* '' */
.saf-cpu-charge:before { content: '\e8fd'; } /* '' */
.saf-cpu-setting:before { content: '\e8fe'; } /* '' */
.saf-cpu:before { content: '\e8ff'; } /* '' */
.saf-creative-commons:before { content: '\e900'; } /* '' */
.saf-crop:before { content: '\e901'; } /* '' */
.saf-crown-1:before { content: '\e902'; } /* '' */
.saf-crown:before { content: '\e903'; } /* '' */
.saf-dai-(dai):before { content: '\e904'; } /* '' */
.saf-cup:before { content: '\e905'; } /* '' */
.saf-danger:before { content: '\e906'; } /* '' */
.saf-data-2:before { content: '\e907'; } /* '' */
.saf-dash-(dash):before { content: '\e908'; } /* '' */
.saf-data:before { content: '\e909'; } /* '' */
.saf-decred-(dcr):before { content: '\e90a'; } /* '' */
.saf-dent-(dent):before { content: '\e90b'; } /* '' */
.saf-designtools:before { content: '\e90c'; } /* '' */
.saf-device-message:before { content: '\e90d'; } /* '' */
.saf-devices-1:before { content: '\e90e'; } /* '' */
.saf-devices:before { content: '\e90f'; } /* '' */
.saf-diagram:before { content: '\e910'; } /* '' */
.saf-diamonds:before { content: '\e911'; } /* '' */
.saf-braille:before { content: '\e912'; } /* '' */
.saf-direct-inbox:before { content: '\e913'; } /* '' */
.saf-direct-left:before { content: '\e914'; } /* '' */
.saf-direct-normal:before { content: '\e915'; } /* '' */
.saf-direct-notification:before { content: '\e916'; } /* '' */
.saf-direct-right:before { content: '\e917'; } /* '' */
.saf-direct-send:before { content: '\e918'; } /* '' */
.saf-direct-up:before { content: '\e919'; } /* '' */
.saf-direct:before { content: '\e91a'; } /* '' */
.saf-directbox-default:before { content: '\e91b'; } /* '' */
.saf-directbox-notif:before { content: '\e91c'; } /* '' */
.saf-directbox-receive:before { content: '\e91d'; } /* '' */
.saf-directbox-send:before { content: '\e91e'; } /* '' */
.saf-discount-circle:before { content: '\e91f'; } /* '' */
.saf-discount-shape:before { content: '\e920'; } /* '' */
.saf-discover-1:before { content: '\e921'; } /* '' */
.saf-discover:before { content: '\e922'; } /* '' */
.saf-dislike:before { content: '\e923'; } /* '' */
.saf-document-1:before { content: '\e924'; } /* '' */
.saf-document-cloud:before { content: '\e925'; } /* '' */
.saf-document-code-2:before { content: '\e926'; } /* '' */
.saf-document-download:before { content: '\e927'; } /* '' */
.saf-document-code:before { content: '\e928'; } /* '' */
.saf-document-filter:before { content: '\e929'; } /* '' */
.saf-document-copy:before { content: '\e92a'; } /* '' */
.saf-document-favorite:before { content: '\e92b'; } /* '' */
.saf-document-forward:before { content: '\e92c'; } /* '' */
.saf-document-like:before { content: '\e92d'; } /* '' */
.saf-document-normal:before { content: '\e92e'; } /* '' */
.saf-document-previous:before { content: '\e92f'; } /* '' */
.saf-document-sketch:before { content: '\e930'; } /* '' */
.saf-document-text:before { content: '\e931'; } /* '' */
.saf-document-text-1:before { content: '\e932'; } /* '' */
.saf-document-upload:before { content: '\e933'; } /* '' */
.saf-document:before { content: '\e934'; } /* '' */
.saf-dollar-circle:before { content: '\e935'; } /* '' */
.saf-dollar-square:before { content: '\e936'; } /* '' */
.saf-dribbble:before { content: '\e937'; } /* '' */
.saf-driver-2:before { content: '\e938'; } /* '' */
.saf-driver-refresh:before { content: '\e939'; } /* '' */
.saf-driver:before { content: '\e93a'; } /* '' */
.saf-driving:before { content: '\e93b'; } /* '' */
.saf-edit-2:before { content: '\e93c'; } /* '' */
.saf-dropbox:before { content: '\e93d'; } /* '' */
.saf-edit:before { content: '\e93e'; } /* '' */
.saf-educare-(ekt):before { content: '\e93f'; } /* '' */
.saf-electricity:before { content: '\e940'; } /* '' */
.saf-element-2:before { content: '\e941'; } /* '' */
.saf-element-3:before { content: '\e942'; } /* '' */
.saf-element-4:before { content: '\e943'; } /* '' */
.saf-element-equal:before { content: '\e944'; } /* '' */
.saf-element-plus:before { content: '\e945'; } /* '' */
.saf-emercoin-(emc):before { content: '\e946'; } /* '' */
.saf-emoji-happy:before { content: '\e947'; } /* '' */
.saf-emoji-normal:before { content: '\e948'; } /* '' */
.saf-emoji-sad:before { content: '\e949'; } /* '' */
.saf-empty-wallet-add:before { content: '\e94a'; } /* '' */
.saf-empty-wallet-change:before { content: '\e94b'; } /* '' */
.saf-empty-wallet-remove:before { content: '\e94c'; } /* '' */
.saf-empty-wallet-tick:before { content: '\e94d'; } /* '' */
.saf-empty-wallet-time:before { content: '\e94e'; } /* '' */
.saf-empty-wallet:before { content: '\e94f'; } /* '' */
.saf-enjin-coin-(enj):before { content: '\e950'; } /* '' */
.saf-eos-(eos):before { content: '\e951'; } /* '' */
.saf-eraser-1:before { content: '\e952'; } /* '' */
.saf-eraser:before { content: '\e953'; } /* '' */
.saf-ethereum-(eth):before { content: '\e954'; } /* '' */
.saf-ethereum-classic-(etc):before { content: '\e955'; } /* '' */
.saf-export-1:before { content: '\e956'; } /* '' */
.saf-export-2:before { content: '\e957'; } /* '' */
.saf-export-3:before { content: '\e958'; } /* '' */
.saf-export:before { content: '\e959'; } /* '' */
.saf-external-drive:before { content: '\e95a'; } /* '' */
.saf-eye-slash:before { content: '\e95b'; } /* '' */
.saf-eye:before { content: '\e95c'; } /* '' */
.saf-facebook-1:before { content: '\e95d'; } /* '' */
.saf-fatrows:before { content: '\e95e'; } /* '' */
.saf-favorite-chart:before { content: '\e95f'; } /* '' */
.saf-figma-1:before { content: '\e960'; } /* '' */
.saf-figma:before { content: '\e961'; } /* '' */
.saf-filter-add:before { content: '\e962'; } /* '' */
.saf-filter-edit:before { content: '\e963'; } /* '' */
.saf-filter-remove:before { content: '\e964'; } /* '' */
.saf-filter-square:before { content: '\e965'; } /* '' */
.saf-filter-tick:before { content: '\e966'; } /* '' */
.saf-filter-search:before { content: '\e967'; } /* '' */
.saf-filter:before { content: '\e968'; } /* '' */
.saf-finger-scan:before { content: '\e969'; } /* '' */
.saf-finger-cricle:before { content: '\e96a'; } /* '' */
.saf-firstline:before { content: '\e96b'; } /* '' */
.saf-flag-2:before { content: '\e96c'; } /* '' */
.saf-flag:before { content: '\e96d'; } /* '' */
.saf-flash-1:before { content: '\e96e'; } /* '' */
.saf-flash-circle-1:before { content: '\e96f'; } /* '' */
.saf-flash-slash:before { content: '\e970'; } /* '' */
.saf-flash-circle:before { content: '\e971'; } /* '' */
.saf-flash:before { content: '\e972'; } /* '' */
.saf-folder-2:before { content: '\e973'; } /* '' */
.saf-folder-add:before { content: '\e974'; } /* '' */
.saf-folder-cloud:before { content: '\e975'; } /* '' */
.saf-folder-cross:before { content: '\e976'; } /* '' */
.saf-folder-connection:before { content: '\e977'; } /* '' */
.saf-folder-favorite:before { content: '\e978'; } /* '' */
.saf-folder-minus:before { content: '\e979'; } /* '' */
.saf-folder-open:before { content: '\e97a'; } /* '' */
.saf-folder:before { content: '\e97b'; } /* '' */
.saf-forbidden-2:before { content: '\e97c'; } /* '' */
.saf-forbidden:before { content: '\e97d'; } /* '' */
.saf-format-circle:before { content: '\e97e'; } /* '' */
.saf-format-square:before { content: '\e97f'; } /* '' */
.saf-forward-5-seconds:before { content: '\e980'; } /* '' */
.saf-forward-10-seconds:before { content: '\e981'; } /* '' */
.saf-forward-15-seconds:before { content: '\e982'; } /* '' */
.saf-forward-item:before { content: '\e983'; } /* '' */
.saf-forward-square:before { content: '\e984'; } /* '' */
.saf-forward:before { content: '\e985'; } /* '' */
.saf-frame-1:before { content: '\e986'; } /* '' */
.saf-frame-2:before { content: '\e987'; } /* '' */
.saf-frame-3:before { content: '\e988'; } /* '' */
.saf-frame-4:before { content: '\e989'; } /* '' */
.saf-frame:before { content: '\e98a'; } /* '' */
.saf-framer:before { content: '\e98b'; } /* '' */
.saf-ftx-token-(ftt):before { content: '\e98c'; } /* '' */
.saf-gallery-add:before { content: '\e98d'; } /* '' */
.saf-gallery-edit:before { content: '\e98e'; } /* '' */
.saf-gallery-export:before { content: '\e98f'; } /* '' */
.saf-gallery-favorite:before { content: '\e990'; } /* '' */
.saf-gallery-import:before { content: '\e991'; } /* '' */
.saf-gallery-remove:before { content: '\e992'; } /* '' */
.saf-gallery-slash:before { content: '\e993'; } /* '' */
.saf-gallery-tick:before { content: '\e994'; } /* '' */
.saf-gallery:before { content: '\e995'; } /* '' */
.saf-game:before { content: '\e996'; } /* '' */
.saf-gameboy:before { content: '\e997'; } /* '' */
.saf-gas-station:before { content: '\e998'; } /* '' */
.saf-gemini-2:before { content: '\e999'; } /* '' */
.saf-gemini:before { content: '\e99a'; } /* '' */
.saf-ghost:before { content: '\e99b'; } /* '' */
.saf-gift:before { content: '\e99c'; } /* '' */
.saf-glass-1:before { content: '\e99d'; } /* '' */
.saf-glass:before { content: '\e99e'; } /* '' */
.saf-global-edit:before { content: '\e99f'; } /* '' */
.saf-global-refresh:before { content: '\e9a0'; } /* '' */
.saf-global-search:before { content: '\e9a1'; } /* '' */
.saf-global:before { content: '\e9a2'; } /* '' */
.saf-google-drive:before { content: '\e9a3'; } /* '' */
.saf-google-paly:before { content: '\e9a4'; } /* '' */
.saf-google:before { content: '\e9a5'; } /* '' */
.saf-gps-slash:before { content: '\e9a6'; } /* '' */
.saf-gps:before { content: '\e9a7'; } /* '' */
.saf-grammerly:before { content: '\e9a8'; } /* '' */
.saf-graph:before { content: '\e9a9'; } /* '' */
.saf-grid-1:before { content: '\e9aa'; } /* '' */
.saf-grid-2:before { content: '\e9ab'; } /* '' */
.saf-grid-3:before { content: '\e9ac'; } /* '' */
.saf-grid-4:before { content: '\e9ad'; } /* '' */
.saf-grid-5:before { content: '\e9ae'; } /* '' */
.saf-grid-6:before { content: '\e9af'; } /* '' */
.saf-grid-7:before { content: '\e9b0'; } /* '' */
.saf-grid-8:before { content: '\e9b1'; } /* '' */
.saf-grid-9:before { content: '\e9b2'; } /* '' */
.saf-grid-edit:before { content: '\e9b3'; } /* '' */
.saf-grid-eraser:before { content: '\e9b4'; } /* '' */
.saf-grid-lock:before { content: '\e9b5'; } /* '' */
.saf-happyemoji:before { content: '\e9b6'; } /* '' */
.saf-harmony-(one):before { content: '\e9b7'; } /* '' */
.saf-hashtag-1:before { content: '\e9b8'; } /* '' */
.saf-hashtag-down:before { content: '\e9b9'; } /* '' */
.saf-hashtag-up:before { content: '\e9ba'; } /* '' */
.saf-hashtag:before { content: '\e9bb'; } /* '' */
.saf-headphone:before { content: '\e9bc'; } /* '' */
.saf-headphones:before { content: '\e9bd'; } /* '' */
.saf-health:before { content: '\e9be'; } /* '' */
.saf-heart-add:before { content: '\e9bf'; } /* '' */
.saf-heart-circle:before { content: '\e9c0'; } /* '' */
.saf-heart-edit:before { content: '\e9c1'; } /* '' */
.saf-heart-remove:before { content: '\e9c2'; } /* '' */
.saf-heart-search:before { content: '\e9c3'; } /* '' */
.saf-heart-slash:before { content: '\e9c4'; } /* '' */
.saf-heart-tick:before { content: '\e9c5'; } /* '' */
.saf-heart:before { content: '\e9c6'; } /* '' */
.saf-hedera-hashgraph-(hbar):before { content: '\e9c7'; } /* '' */
.saf-hex-(hex):before { content: '\e9c8'; } /* '' */
.saf-hierarchy-2:before { content: '\e9c9'; } /* '' */
.saf-hierarchy-3:before { content: '\e9ca'; } /* '' */
.saf-hierarchy-square-2:before { content: '\e9cb'; } /* '' */
.saf-hierarchy-square-3:before { content: '\e9cc'; } /* '' */
.saf-hierarchy-square:before { content: '\e9cd'; } /* '' */
.saf-hierarchy:before { content: '\e9ce'; } /* '' */
.saf-home-1:before { content: '\e9cf'; } /* '' */
.saf-home-2:before { content: '\e9d0'; } /* '' */
.saf-home-hashtag:before { content: '\e9d1'; } /* '' */
.saf-home-trend-down:before { content: '\e9d2'; } /* '' */
.saf-home-trend-up:before { content: '\e9d3'; } /* '' */
.saf-home-wifi:before { content: '\e9d4'; } /* '' */
.saf-home:before { content: '\e9d5'; } /* '' */
.saf-hospital:before { content: '\e9d6'; } /* '' */
.saf-house-2:before { content: '\e9d7'; } /* '' */
.saf-html-3:before { content: '\e9d8'; } /* '' */
.saf-house:before { content: '\e9d9'; } /* '' */
.saf-html-5:before { content: '\e9da'; } /* '' */
.saf-huobi-token-(ht):before { content: '\e9db'; } /* '' */
.saf-icon-(icx):before { content: '\e9dc'; } /* '' */
.saf-icon-1:before { content: '\e9dd'; } /* '' */
.saf-icon-2:before { content: '\e9de'; } /* '' */
.saf-icon:before { content: '\e9df'; } /* '' */
.saf-illustrator:before { content: '\e9e0'; } /* '' */
.saf-image:before { content: '\e9e1'; } /* '' */
.saf-import-1:before { content: '\e9e2'; } /* '' */
.saf-import-2:before { content: '\e9e3'; } /* '' */
.saf-import:before { content: '\e9e4'; } /* '' */
.saf-info-circle:before { content: '\e9e5'; } /* '' */
.saf-instagram:before { content: '\e9e6'; } /* '' */
.saf-information:before { content: '\e9e7'; } /* '' */
.saf-iost-(iost):before { content: '\e9e8'; } /* '' */
.saf-java-script:before { content: '\e9e9'; } /* '' */
.saf-js:before { content: '\e9ea'; } /* '' */
.saf-judge:before { content: '\e9eb'; } /* '' */
.saf-kanban:before { content: '\e9ec'; } /* '' */
.saf-key-square:before { content: '\e9ed'; } /* '' */
.saf-key:before { content: '\e9ee'; } /* '' */
.saf-keyboard-open:before { content: '\e9ef'; } /* '' */
.saf-keyboard:before { content: '\e9f0'; } /* '' */
.saf-kyber-network-(knc):before { content: '\e9f1'; } /* '' */
.saf-lamp-1:before { content: '\e9f2'; } /* '' */
.saf-lamp-on:before { content: '\e9f3'; } /* '' */
.saf-lamp-charge:before { content: '\e9f4'; } /* '' */
.saf-lamp-slash:before { content: '\e9f5'; } /* '' */
.saf-lamp:before { content: '\e9f6'; } /* '' */
.saf-language-circle:before { content: '\e9f7'; } /* '' */
.saf-language-square:before { content: '\e9f8'; } /* '' */
.saf-layer:before { content: '\e9f9'; } /* '' */
.saf-level:before { content: '\e9fa'; } /* '' */
.saf-lifebuoy:before { content: '\e9fb'; } /* '' */
.saf-like-1:before { content: '\e9fc'; } /* '' */
.saf-like-dislike:before { content: '\e9fd'; } /* '' */
.saf-like-shapes:before { content: '\e9fe'; } /* '' */
.saf-like-tag:before { content: '\e9ff'; } /* '' */
.saf-like:before { content: '\ea00'; } /* '' */
.saf-link-1:before { content: '\ea01'; } /* '' */
.saf-link-2:before { content: '\ea02'; } /* '' */
.saf-link-21:before { content: '\ea03'; } /* '' */
.saf-link-circle:before { content: '\ea04'; } /* '' */
.saf-link-square:before { content: '\ea05'; } /* '' */
.saf-link:before { content: '\ea06'; } /* '' */
.saf-litecoin(ltc):before { content: '\ea07'; } /* '' */
.saf-location-add:before { content: '\ea08'; } /* '' */
.saf-location-cross:before { content: '\ea09'; } /* '' */
.saf-location-minus:before { content: '\ea0a'; } /* '' */
.saf-location-slash:before { content: '\ea0b'; } /* '' */
.saf-location-tick:before { content: '\ea0c'; } /* '' */
.saf-location:before { content: '\ea0d'; } /* '' */
.saf-lock-1:before { content: '\ea0e'; } /* '' */
.saf-lock-circle:before { content: '\ea0f'; } /* '' */
.saf-lock-slash:before { content: '\ea10'; } /* '' */
.saf-lock:before { content: '\ea11'; } /* '' */
.saf-login-1:before { content: '\ea12'; } /* '' */
.saf-login:before { content: '\ea13'; } /* '' */
.saf-logout-1:before { content: '\ea14'; } /* '' */
.saf-logout:before { content: '\ea15'; } /* '' */
.saf-lovely:before { content: '\ea16'; } /* '' */
.saf-magic-star:before { content: '\ea17'; } /* '' */
.saf-magicpen:before { content: '\ea18'; } /* '' */
.saf-main-component:before { content: '\ea19'; } /* '' */
.saf-maker-(mkr):before { content: '\ea1a'; } /* '' */
.saf-man:before { content: '\ea1b'; } /* '' */
.saf-map-1:before { content: '\ea1c'; } /* '' */
.saf-map:before { content: '\ea1d'; } /* '' */
.saf-mask-1:before { content: '\ea1e'; } /* '' */
.saf-mask-2:before { content: '\ea1f'; } /* '' */
.saf-mask:before { content: '\ea20'; } /* '' */
.saf-math:before { content: '\ea21'; } /* '' */
.saf-maximize-1:before { content: '\ea22'; } /* '' */
.saf-maximize-2:before { content: '\ea23'; } /* '' */
.saf-maximize-4:before { content: '\ea24'; } /* '' */
.saf-maximize-3:before { content: '\ea25'; } /* '' */
.saf-maximize-21:before { content: '\ea26'; } /* '' */
.saf-maximize-circle:before { content: '\ea27'; } /* '' */
.saf-maximize:before { content: '\ea28'; } /* '' */
.saf-medal-star:before { content: '\ea29'; } /* '' */
.saf-medal:before { content: '\ea2a'; } /* '' */
.saf-menu-1:before { content: '\ea2b'; } /* '' */
.saf-menu-board:before { content: '\ea2c'; } /* '' */
.saf-menu:before { content: '\ea2d'; } /* '' */
.saf-message-2:before { content: '\ea2e'; } /* '' */
.saf-message-add-1:before { content: '\ea2f'; } /* '' */
.saf-message-add:before { content: '\ea30'; } /* '' */
.saf-message-circle:before { content: '\ea31'; } /* '' */
.saf-message-edit:before { content: '\ea32'; } /* '' */
.saf-message-favorite:before { content: '\ea33'; } /* '' */
.saf-message-minus:before { content: '\ea34'; } /* '' */
.saf-message-notif:before { content: '\ea35'; } /* '' */
.saf-message-programming:before { content: '\ea36'; } /* '' */
.saf-message-question:before { content: '\ea37'; } /* '' */
.saf-message-remove:before { content: '\ea38'; } /* '' */
.saf-message-search:before { content: '\ea39'; } /* '' */
.saf-message-square:before { content: '\ea3a'; } /* '' */
.saf-message-text-1:before { content: '\ea3b'; } /* '' */
.saf-message-text:before { content: '\ea3c'; } /* '' */
.saf-message-tick:before { content: '\ea3d'; } /* '' */
.saf-message-time:before { content: '\ea3e'; } /* '' */
.saf-message:before { content: '\ea3f'; } /* '' */
.saf-messages-1:before { content: '\ea40'; } /* '' */
.saf-messages-2:before { content: '\ea41'; } /* '' */
.saf-messages-3:before { content: '\ea42'; } /* '' */
.saf-messages:before { content: '\ea43'; } /* '' */
.saf-messenger:before { content: '\ea44'; } /* '' */
.saf-microphone-2:before { content: '\ea45'; } /* '' */
.saf-microphone-slash-1:before { content: '\ea46'; } /* '' */
.saf-microphone-slash:before { content: '\ea47'; } /* '' */
.saf-microphone:before { content: '\ea48'; } /* '' */
.saf-microscope:before { content: '\ea49'; } /* '' */
.saf-milk:before { content: '\ea4a'; } /* '' */
.saf-mini-music-sqaure:before { content: '\ea4b'; } /* '' */
.saf-minus-cirlce:before { content: '\ea4c'; } /* '' */
.saf-minus-square:before { content: '\ea4d'; } /* '' */
.saf-minus:before { content: '\ea4e'; } /* '' */
.saf-mirror:before { content: '\ea4f'; } /* '' */
.saf-mirroring-screen:before { content: '\ea50'; } /* '' */
.saf-mobile-programming:before { content: '\ea51'; } /* '' */
.saf-mobile:before { content: '\ea52'; } /* '' */
.saf-monero-(xmr):before { content: '\ea53'; } /* '' */
.saf-money-2:before { content: '\ea54'; } /* '' */
.saf-money-3:before { content: '\ea55'; } /* '' */
.saf-money-4:before { content: '\ea56'; } /* '' */
.saf-money-add:before { content: '\ea57'; } /* '' */
.saf-money-change:before { content: '\ea58'; } /* '' */
.saf-money-forbidden:before { content: '\ea59'; } /* '' */
.saf-money-recive:before { content: '\ea5a'; } /* '' */
.saf-money-remove:before { content: '\ea5b'; } /* '' */
.saf-money-send:before { content: '\ea5c'; } /* '' */
.saf-money-tick:before { content: '\ea5d'; } /* '' */
.saf-money-time:before { content: '\ea5e'; } /* '' */
.saf-money:before { content: '\ea5f'; } /* '' */
.saf-moneys:before { content: '\ea60'; } /* '' */
.saf-monitor-mobbile:before { content: '\ea61'; } /* '' */
.saf-monitor-recorder:before { content: '\ea62'; } /* '' */
.saf-monitor:before { content: '\ea63'; } /* '' */
.saf-more-2:before { content: '\ea64'; } /* '' */
.saf-more-circle:before { content: '\ea65'; } /* '' */
.saf-more-square:before { content: '\ea66'; } /* '' */
.saf-moon:before { content: '\ea67'; } /* '' */
.saf-more:before { content: '\ea68'; } /* '' */
.saf-mouse-1:before { content: '\ea69'; } /* '' */
.saf-mouse-circle:before { content: '\ea6a'; } /* '' */
.saf-mouse-square:before { content: '\ea6b'; } /* '' */
.saf-mouse:before { content: '\ea6c'; } /* '' */
.saf-music-circle:before { content: '\ea6d'; } /* '' */
.saf-music-dashboard:before { content: '\ea6e'; } /* '' */
.saf-music-filter:before { content: '\ea6f'; } /* '' */
.saf-music-library-2:before { content: '\ea70'; } /* '' */
.saf-music-play:before { content: '\ea71'; } /* '' */
.saf-music-playlist:before { content: '\ea72'; } /* '' */
.saf-music-square-add:before { content: '\ea73'; } /* '' */
.saf-music-square-remove:before { content: '\ea74'; } /* '' */
.saf-music-square-search:before { content: '\ea75'; } /* '' */
.saf-music-square:before { content: '\ea76'; } /* '' */
.saf-music:before { content: '\ea77'; } /* '' */
.saf-musicnote:before { content: '\ea78'; } /* '' */
.saf-nebulas-(nas):before { content: '\ea79'; } /* '' */
.saf-nem-(xem):before { content: '\ea7a'; } /* '' */
.saf-nexo-(nexo):before { content: '\ea7b'; } /* '' */
.saf-next:before { content: '\ea7c'; } /* '' */
.saf-note-1:before { content: '\ea7d'; } /* '' */
.saf-note-2:before { content: '\ea7e'; } /* '' */
.saf-note-21:before { content: '\ea7f'; } /* '' */
.saf-note-add:before { content: '\ea80'; } /* '' */
.saf-note-favorite:before { content: '\ea81'; } /* '' */
.saf-note-remove:before { content: '\ea82'; } /* '' */
.saf-note-square:before { content: '\ea83'; } /* '' */
.saf-note-text:before { content: '\ea84'; } /* '' */
.saf-note:before { content: '\ea85'; } /* '' */
.saf-notification-1:before { content: '\ea86'; } /* '' */
.saf-notification-bing:before { content: '\ea87'; } /* '' */
.saf-notification-circle:before { content: '\ea88'; } /* '' */
.saf-notification-favorite:before { content: '\ea89'; } /* '' */
.saf-notification-status:before { content: '\ea8a'; } /* '' */
.saf-notification:before { content: '\ea8b'; } /* '' */
.saf-ocean-protocol-(ocean):before { content: '\ea8c'; } /* '' */
.saf-okb-(okb):before { content: '\ea8d'; } /* '' */
.saf-omega-circle:before { content: '\ea8e'; } /* '' */
.saf-omega-square:before { content: '\ea8f'; } /* '' */
.saf-ontology-(ont):before { content: '\ea90'; } /* '' */
.saf-paintbucket:before { content: '\ea91'; } /* '' */
.saf-paperclip-2:before { content: '\ea92'; } /* '' */
.saf-paperclip:before { content: '\ea93'; } /* '' */
.saf-password-check:before { content: '\ea94'; } /* '' */
.saf-path-2:before { content: '\ea95'; } /* '' */
.saf-path-square:before { content: '\ea96'; } /* '' */
.saf-path:before { content: '\ea97'; } /* '' */
.saf-pause-circle:before { content: '\ea98'; } /* '' */
.saf-pause:before { content: '\ea99'; } /* '' */
.saf-paypal:before { content: '\ea9a'; } /* '' */
.saf-pen-add:before { content: '\ea9b'; } /* '' */
.saf-pen-close:before { content: '\ea9c'; } /* '' */
.saf-pen-remove:before { content: '\ea9d'; } /* '' */
.saf-pen-tool-2:before { content: '\ea9e'; } /* '' */
.saf-pen-tool:before { content: '\ea9f'; } /* '' */
.saf-people:before { content: '\eaa0'; } /* '' */
.saf-percentage-circle:before { content: '\eaa1'; } /* '' */
.saf-percentage-square:before { content: '\eaa2'; } /* '' */
.saf-personalcard:before { content: '\eaa3'; } /* '' */
.saf-pet:before { content: '\eaa4'; } /* '' */
.saf-pharagraphspacing:before { content: '\eaa5'; } /* '' */
.saf-photoshop:before { content: '\eaa6'; } /* '' */
.saf-picture-frame:before { content: '\eaa7'; } /* '' */
.saf-play-add:before { content: '\eaa8'; } /* '' */
.saf-play-circle:before { content: '\eaa9'; } /* '' */
.saf-play-cricle:before { content: '\eaaa'; } /* '' */
.saf-play-remove:before { content: '\eaab'; } /* '' */
.saf-play:before { content: '\eaac'; } /* '' */
.saf-polkadot-(dot):before { content: '\eaad'; } /* '' */
.saf-polygon-(matic):before { content: '\eaae'; } /* '' */
.saf-polyswarm-(nct):before { content: '\eaaf'; } /* '' */
.saf-presention-chart:before { content: '\eab0'; } /* '' */
.saf-previous:before { content: '\eab1'; } /* '' */
.saf-printer-slash:before { content: '\eab2'; } /* '' */
.saf-printer:before { content: '\eab3'; } /* '' */
.saf-profile-2user:before { content: '\eab4'; } /* '' */
.saf-profile-add:before { content: '\eab5'; } /* '' */
.saf-profile-circle:before { content: '\eab6'; } /* '' */
.saf-profile-delete:before { content: '\eab7'; } /* '' */
.saf-profile-remove:before { content: '\eab8'; } /* '' */
.saf-profile-tick:before { content: '\eab9'; } /* '' */
.saf-programming-arrow:before { content: '\eaba'; } /* '' */
.saf-programming-arrows:before { content: '\eabb'; } /* '' */
.saf-python:before { content: '\eabc'; } /* '' */
.saf-quant-(qnt):before { content: '\eabd'; } /* '' */
.saf-quote-down-circle:before { content: '\eabe'; } /* '' */
.saf-quote-down-square:before { content: '\eabf'; } /* '' */
.saf-quote-down:before { content: '\eac0'; } /* '' */
.saf-quote-up-circle:before { content: '\eac1'; } /* '' */
.saf-quote-up-square:before { content: '\eac2'; } /* '' */
.saf-quote-up:before { content: '\eac3'; } /* '' */
.saf-radar-1:before { content: '\eac4'; } /* '' */
.saf-radar-2:before { content: '\eac5'; } /* '' */
.saf-radar:before { content: '\eac6'; } /* '' */
.saf-radio:before { content: '\eac7'; } /* '' */
.saf-ram-2:before { content: '\eac8'; } /* '' */
.saf-ram:before { content: '\eac9'; } /* '' */
.saf-ranking-1:before { content: '\eaca'; } /* '' */
.saf-ranking:before { content: '\eacb'; } /* '' */
.saf-receipt-1:before { content: '\eacc'; } /* '' */
.saf-receipt-2-1:before { content: '\eacd'; } /* '' */
.saf-receipt-2:before { content: '\eace'; } /* '' */
.saf-receipt-add:before { content: '\eacf'; } /* '' */
.saf-receipt-discount:before { content: '\ead0'; } /* '' */
.saf-receipt-disscount:before { content: '\ead1'; } /* '' */
.saf-receipt-edit:before { content: '\ead2'; } /* '' */
.saf-receipt-item:before { content: '\ead3'; } /* '' */
.saf-receipt-minus:before { content: '\ead4'; } /* '' */
.saf-receipt-search:before { content: '\ead5'; } /* '' */
.saf-receipt-square:before { content: '\ead6'; } /* '' */
.saf-receipt-text:before { content: '\ead7'; } /* '' */
.saf-receipt:before { content: '\ead8'; } /* '' */
.saf-receive-square-2:before { content: '\ead9'; } /* '' */
.saf-receive-square:before { content: '\eada'; } /* '' */
.saf-received:before { content: '\eadb'; } /* '' */
.saf-record-circle:before { content: '\eadc'; } /* '' */
.saf-record:before { content: '\eadd'; } /* '' */
.saf-recovery-convert:before { content: '\eade'; } /* '' */
.saf-redo:before { content: '\eadf'; } /* '' */
.saf-refresh-2:before { content: '\eae0'; } /* '' */
.saf-refresh-circle:before { content: '\eae1'; } /* '' */
.saf-refresh-left-square:before { content: '\eae2'; } /* '' */
.saf-refresh-right-square:before { content: '\eae3'; } /* '' */
.saf-refresh-square-2:before { content: '\eae4'; } /* '' */
.saf-refresh:before { content: '\eae5'; } /* '' */
.saf-repeat-circle:before { content: '\eae6'; } /* '' */
.saf-repeat:before { content: '\eae7'; } /* '' */
.saf-repeate-music:before { content: '\eae8'; } /* '' */
.saf-repeate-one:before { content: '\eae9'; } /* '' */
.saf-reserve:before { content: '\eaea'; } /* '' */
.saf-rotate-left-1:before { content: '\eaeb'; } /* '' */
.saf-rotate-left:before { content: '\eaec'; } /* '' */
.saf-rotate-right-1:before { content: '\eaed'; } /* '' */
.saf-rotate-right:before { content: '\eaee'; } /* '' */
.saf-route-square:before { content: '\eaef'; } /* '' */
.saf-routing-2:before { content: '\eaf0'; } /* '' */
.saf-routing:before { content: '\eaf1'; } /* '' */
.saf-row-horizontal:before { content: '\eaf2'; } /* '' */
.saf-row-vertical:before { content: '\eaf3'; } /* '' */
.saf-ruler:before { content: '\eaf4'; } /* '' */
.saf-ruler&pen:before { content: '\eaf5'; } /* '' */
.saf-safe-home:before { content: '\eaf6'; } /* '' */
.saf-sagittarius:before { content: '\eaf7'; } /* '' */
.saf-save-2:before { content: '\eaf8'; } /* '' */
.saf-save-add:before { content: '\eaf9'; } /* '' */
.saf-save-minus:before { content: '\eafa'; } /* '' */
.saf-save-remove:before { content: '\eafb'; } /* '' */
.saf-scan-barcode:before { content: '\eafc'; } /* '' */
.saf-scan:before { content: '\eafd'; } /* '' */
.saf-scanner:before { content: '\eafe'; } /* '' */
.saf-scanning:before { content: '\eaff'; } /* '' */
.saf-scissor-1:before { content: '\eb00'; } /* '' */
.saf-scissor:before { content: '\eb01'; } /* '' */
.saf-screenmirroring:before { content: '\eb02'; } /* '' */
.saf-scroll:before { content: '\eb03'; } /* '' */
.saf-search-favorite-1:before { content: '\eb04'; } /* '' */
.saf-search-favorite:before { content: '\eb05'; } /* '' */
.saf-search-normal-1:before { content: '\eb06'; } /* '' */
.saf-search-normal:before { content: '\eb07'; } /* '' */
.saf-search-status-1:before { content: '\eb08'; } /* '' */
.saf-search-status:before { content: '\eb09'; } /* '' */
.saf-search-zoom-in-1:before { content: '\eb0a'; } /* '' */
.saf-search-zoom-in:before { content: '\eb0b'; } /* '' */
.saf-search-zoom-out-1:before { content: '\eb0c'; } /* '' */
.saf-search-zoom-out:before { content: '\eb0d'; } /* '' */
.saf-security-card:before { content: '\eb0e'; } /* '' */
.saf-security-safe:before { content: '\eb0f'; } /* '' */
.saf-security-time:before { content: '\eb10'; } /* '' */
.saf-security-user:before { content: '\eb11'; } /* '' */
.saf-security:before { content: '\eb12'; } /* '' */
.saf-send-1:before { content: '\eb13'; } /* '' */
.saf-send-2:before { content: '\eb14'; } /* '' */
.saf-send-sqaure-2:before { content: '\eb15'; } /* '' */
.saf-send-square:before { content: '\eb16'; } /* '' */
.saf-send:before { content: '\eb17'; } /* '' */
.saf-setting-2:before { content: '\eb18'; } /* '' */
.saf-setting-3:before { content: '\eb19'; } /* '' */
.saf-setting-4:before { content: '\eb1a'; } /* '' */
.saf-setting-5:before { content: '\eb1b'; } /* '' */
.saf-setting:before { content: '\eb1c'; } /* '' */
.saf-settings:before { content: '\eb1d'; } /* '' */
.saf-shapes-1:before { content: '\eb1e'; } /* '' */
.saf-shapes:before { content: '\eb1f'; } /* '' */
.saf-share:before { content: '\eb20'; } /* '' */
.saf-shield-cross:before { content: '\eb21'; } /* '' */
.saf-shield-search:before { content: '\eb22'; } /* '' */
.saf-shield-slash:before { content: '\eb23'; } /* '' */
.saf-shield-tick:before { content: '\eb24'; } /* '' */
.saf-shield:before { content: '\eb25'; } /* '' */
.saf-ship:before { content: '\eb26'; } /* '' */
.saf-shop-add:before { content: '\eb27'; } /* '' */
.saf-shop-remove:before { content: '\eb28'; } /* '' */
.saf-shop:before { content: '\eb29'; } /* '' */
.saf-shopping-bag:before { content: '\eb2a'; } /* '' */
.saf-shopping-cart:before { content: '\eb2b'; } /* '' */
.saf-shuffle:before { content: '\eb2c'; } /* '' */
.saf-siacoin-(sc):before { content: '\eb2d'; } /* '' */
.saf-sidebar-bottom:before { content: '\eb2e'; } /* '' */
.saf-sidebar-left:before { content: '\eb2f'; } /* '' */
.saf-sidebar-right:before { content: '\eb30'; } /* '' */
.saf-sidebar-top:before { content: '\eb31'; } /* '' */
.saf-signpost:before { content: '\eb32'; } /* '' */
.saf-simcard-1:before { content: '\eb33'; } /* '' */
.saf-simcard-2:before { content: '\eb34'; } /* '' */
.saf-simcard:before { content: '\eb35'; } /* '' */
.saf-size:before { content: '\eb36'; } /* '' */
.saf-slack:before { content: '\eb37'; } /* '' */
.saf-slash:before { content: '\eb38'; } /* '' */
.saf-slider-horizontal-1:before { content: '\eb39'; } /* '' */
.saf-slider-horizontal:before { content: '\eb3a'; } /* '' */
.saf-slider-vertical-1:before { content: '\eb3b'; } /* '' */
.saf-slider-vertical:before { content: '\eb3c'; } /* '' */
.saf-slider:before { content: '\eb3d'; } /* '' */
.saf-smallcaps:before { content: '\eb3e'; } /* '' */
.saf-smart-car:before { content: '\eb3f'; } /* '' */
.saf-smart-home:before { content: '\eb40'; } /* '' */
.saf-smileys:before { content: '\eb41'; } /* '' */
.saf-sms-edit:before { content: '\eb42'; } /* '' */
.saf-sms-notification:before { content: '\eb43'; } /* '' */
.saf-sms-search:before { content: '\eb44'; } /* '' */
.saf-sms-star:before { content: '\eb45'; } /* '' */
.saf-sms-tracking:before { content: '\eb46'; } /* '' */
.saf-sms:before { content: '\eb47'; } /* '' */
.saf-snapchat:before { content: '\eb48'; } /* '' */
.saf-solana-(sol):before { content: '\eb49'; } /* '' */
.saf-sort:before { content: '\eb4a'; } /* '' */
.saf-sound:before { content: '\eb4b'; } /* '' */
.saf-speaker:before { content: '\eb4c'; } /* '' */
.saf-speedometer:before { content: '\eb4d'; } /* '' */
.saf-spotify:before { content: '\eb4e'; } /* '' */
.saf-stacks-(stx):before { content: '\eb4f'; } /* '' */
.saf-star-1:before { content: '\eb50'; } /* '' */
.saf-star-slash:before { content: '\eb51'; } /* '' */
.saf-star:before { content: '\eb52'; } /* '' */
.saf-status-up:before { content: '\eb53'; } /* '' */
.saf-status:before { content: '\eb54'; } /* '' */
.saf-stellar-(xlm):before { content: '\eb55'; } /* '' */
.saf-sticker:before { content: '\eb56'; } /* '' */
.saf-stickynote:before { content: '\eb57'; } /* '' */
.saf-stop-circle:before { content: '\eb58'; } /* '' */
.saf-stop:before { content: '\eb59'; } /* '' */
.saf-story:before { content: '\eb5a'; } /* '' */
.saf-strongbox-2:before { content: '\eb5b'; } /* '' */
.saf-strongbox:before { content: '\eb5c'; } /* '' */
.saf-subtitle:before { content: '\eb5d'; } /* '' */
.saf-sun-1:before { content: '\eb5e'; } /* '' */
.saf-sun-fog:before { content: '\eb5f'; } /* '' */
.saf-sun:before { content: '\eb60'; } /* '' */
.saf-tag-2:before { content: '\eb61'; } /* '' */
.saf-tag-cross:before { content: '\eb62'; } /* '' */
.saf-tag-right:before { content: '\eb63'; } /* '' */
.saf-tag-user:before { content: '\eb64'; } /* '' */
.saf-tag:before { content: '\eb65'; } /* '' */
.saf-task-square:before { content: '\eb66'; } /* '' */
.saf-task:before { content: '\eb67'; } /* '' */
.saf-teacher:before { content: '\eb68'; } /* '' */
.saf-tenx-(pay):before { content: '\eb69'; } /* '' */
.saf-tether-(usdt):before { content: '\eb6a'; } /* '' */
.saf-text-block:before { content: '\eb6b'; } /* '' */
.saf-text-bold:before { content: '\eb6c'; } /* '' */
.saf-text-italic:before { content: '\eb6d'; } /* '' */
.saf-text-underline:before { content: '\eb6e'; } /* '' */
.saf-text:before { content: '\eb6f'; } /* '' */
.saf-textalign-center:before { content: '\eb70'; } /* '' */
.saf-textalign-justifycenter:before { content: '\eb71'; } /* '' */
.saf-textalign-justifyleft:before { content: '\eb72'; } /* '' */
.saf-textalign-justifyright:before { content: '\eb73'; } /* '' */
.saf-textalign-left:before { content: '\eb74'; } /* '' */
.saf-user-add:before { content: '\eb75'; } /* '' */
.saf-user-cirlce-add:before { content: '\eb76'; } /* '' */
.saf-user-edit:before { content: '\eb77'; } /* '' */
.saf-user-minus:before { content: '\eb78'; } /* '' */
.saf-user-octagon:before { content: '\eb79'; } /* '' */
.saf-user-remove:before { content: '\eb7a'; } /* '' */
.saf-user-search:before { content: '\eb7b'; } /* '' */
.saf-whatsapp:before { content: '\eb7c'; } /* '' */
.saf-user-tag:before { content: '\eb7d'; } /* '' */
.saf-user-tick:before { content: '\eb7e'; } /* '' */
.saf-user:before { content: '\eb7f'; } /* '' */
.saf-family:before { content: '\eb80'; } /* '' */
.saf-staff:before { content: '\eb81'; } /* '' */
.saf-ticket-discount:before { content: '\eb82'; } /* '' */
.saf-ticket-expired:before { content: '\eb83'; } /* '' */
.saf-ticket-star:before { content: '\eb84'; } /* '' */
.saf-ticket:before { content: '\eb85'; } /* '' */
.saf-timer-1:before { content: '\eb86'; } /* '' */
.saf-timer-pause:before { content: '\eb87'; } /* '' */
.saf-timer-start:before { content: '\eb88'; } /* '' */
.saf-toggle-off-circle:before { content: '\eb89'; } /* '' */
.saf-toggle-off:before { content: '\eb8a'; } /* '' */
.saf-toggle-on-circle:before { content: '\eb8b'; } /* '' */
.saf-toggle-on:before { content: '\eb8c'; } /* '' */
.saf-trade:before { content: '\eb8d'; } /* '' */
.saf-translate:before { content: '\eb8e'; } /* '' */
.saf-trash:before { content: '\eb8f'; } /* '' */
.saf-trend-down:before { content: '\eb90'; } /* '' */
.saf-trend-up:before { content: '\eb91'; } /* '' */
.saf-whatsapp-1:before { content: '\eb92'; } /* '' */
.saf-youtube-play:before { content: '\f16a'; } /* '' */
